<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-input
        v-model:value="paymentName"
        style="min-width: 200px"
        :size="size"
        :placeholder="$t('search payment name')"
        @change="debounceQuery"
      />
    </div>
    <!--
      <div class="column is-narrow">
      <a-select
      v-model:value="state"
      :size="size"
      placeholder="Prefecture"
      style="max-width: 200px;"
      @change="debounceQuery"
      >
      <a-select-option value="option">
      option
      </a-select-option>
      </a-select>
      </div>
    -->
    <div class="column">
      <a-button
        v-show="paymentName"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"search payment name": "search payment name",
"Clear": "Clear"
},
"ja": {
"search payment name": "支払い名を検索",
"Clear": "クリア"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'SearchForm',
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      paymentName: undefined,
    };
  },
  mounted() {
    this.paymentName = this.$route.query.paymentName || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(function () {
      this.$router.push({ query: { paymentName: this.paymentName } });
    }, 600),
    handleClear() {
      this.paymentName = undefined;
      this.debounceQuery();
    },
  },
};
</script>

<style scoped></style>
