<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Edit Payment') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <template v-if="canEditPayment">
          <PaymentForm
            ref="form"
            :value="payment"
            :is-submitting="isSubmitting"
            :partner-list="partnerList"
            @submit="handleSubmit"
          />
          <a-alert v-if="error" :message="error" type="error" />
        </template>
        <a-alert v-else :message="$t('error')" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Edit Payment": "Edit Payment",
"error": "You cannot edit this payment"
},
"ja": {
"Edit Payment": "支払いを編集",
"error": "あなたは、この支払いを編集することができません"
}
}
</i18n>

<script>
import PaymentForm from '@/views/payments/components/PaymentForm';
import checkPermission from '@/utils/permission';

export default {
  name: 'Edit',
  components: { PaymentForm },
  data() {
    return {
      payment: undefined,
      isSubmitting: false,
      error: '',
      partnerList: [],
    };
  },
  computed: {
    canEditPayment() {
      return checkPermission(['modify-payment']);
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'payments-edit') this.fetchData();
    },
  },
  created() {
    this.getPartnerList();
    this.fetchData();
  },
  methods: {
    getPartnerList() {
      this.$store.dispatch('partners/list', { infinite: true }).then((res) => {
        this.partnerList = res.list;
      });
    },
    fetchData() {
      this.error = '';
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('payments/get', { id: this.$route.params.id })
        .then((data) => {
          this.payment = data;
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        })
        .catch(() => {
          this.error = this.$t('error');
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleSubmit(data) {
      this.isSubmitting = true;
      this.error = '';
      this.$store
        .dispatch('payments/update', data)
        .then(() => {
          this.isSubmitting = false;
          this.payment = data;
          this.$refs.form.resetFields();
          this.$router.push({ name: 'payments' });
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.error = err.response.data.errors.message;
          }
          this.isSubmitting = false;
        });
    },
  },
};
</script>
