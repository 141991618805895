<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('From Partner')" name="partnerFrom">
      <a-select v-model:value="form.partnerFrom" :size="size" option-filter-prop="key">
        <a-select-option v-for="partner in partnerList" :key="partner.name" :value="partner.id">
          {{ partner.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('To Partner')" name="partnerTo">
      <a-select v-model:value="form.partnerTo" :size="size" option-filter-prop="key">
        <a-select-option
          v-for="partner in filteredPartnerList"
          :key="partner.name"
          :value="partner.id"
        >
          {{ partner.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Fee Type')" name="feeType">
      <a-select v-model:value="form.feeType" :size="size" :placeholder="$t('Please select')">
        <a-select-option value="fixed cost">
          {{ $t('fixed cost') }}
        </a-select-option>
        <a-select-option value="variable cost">
          {{ $t('variable cost') }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Item Name')" name="itemName">
      <a-input v-model:value="form.itemName" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-8" :label="$t('Cost per Month')" name="cost">
      <a-input-number
        v-model:value="form.cost"
        :size="size"
        type="text"
        style="width: 100%;"
      />
    </a-form-item>
    <a-form-item class="column is-4" :label="$t('Currency')" name="currency">
      <InputCurrency v-model:value-model="form.currency" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Covers')" name="covers">
      <a-select v-model:value="form.covers" :size="size">
        <a-select-option value="Per Room">
          {{ $t('Per Room') }}
        </a-select-option>
        <a-select-option value="Per Property">
          {{ $t('Per Property') }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Occurs')" name="occurs">
      <a-select v-model:value="form.occurs" :size="size">
        <a-select-option value="Per Month">
          {{ $t('Per Month') }}
        </a-select-option>
        <a-select-option value="Per Day">
          {{ $t('Per Day') }}
        </a-select-option>
        <a-select-option value="Per Use">
          {{ $t('Per Use') }}
        </a-select-option>
        <a-select-option value="Per Person">
          {{ $t('Per Person') }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Remarks')" name="remarks">
      <a-input v-model:value="form.remarks" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Request Date')" name="requestDate">
      <a-date-picker v-model:value="form.requestDate" :size="size" style="width: 100%" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Payment Period')" name="paymentPeriod">
      <a-date-picker v-model:value="form.paymentPeriod" :size="size" style="width: 100%" />
    </a-form-item>
    <div v-if="value" class="column is-12">
      <span>{{ $t('Status') }}</span
      ><br />
      <span style="color: #888888">{{ value.status }}</span>
    </div>

    <div class="column is-12 m-t-4x" :class="{ 'has-text-right': value }">
      <a-button
        v-if="value"
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"From Partner": "From Partner",
"To Partner": "To Partner",
"Fee Type": "Fee Type",
"Please select": "Please select",
"Item Name": "Item Name",
"Cost per Month": "Cost per Month",
"Currency": "Currency",
"Covers": "Covers",
"Per Room": "Per Room",
"Per Property": "Per Property",
"Occurs": "Occurs",
"Per Month": "Per Month",
"Per Property": "Per Property",
"Per Use": "Per Use",
"Per Person": "Per Person",
"Remarks": "Remarks",
"Request Date": "Request Date",
"Payment Period": "Payment Period",
"Status": "Status",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"From Partner": "支払元",
"To Partner": "支払先",
"Fee Type": "費用種類",
"Please select": "選択してください",
"Item Name": "アイテム名",
"Cost per Month": "月額費",
"Currency": "通貨",
"Covers": "適用範囲",
"Per Room": "部屋毎",
"Per Property": "物件毎",
"Occurs": "頻度",
"Per Month": "月毎",
"Per Use": "利用回数毎",
"Per Person": "人毎",
"Remarks": "備考",
"Request Date": "リクエスト日",
"Payment Period": "支払期間",
"Status": "ステータス",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import InputCurrency from '@/components/InputCurrency';

export default {
  name: 'PaymentForm',
  components: { InputCurrency },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    partnerList: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      form: {
        id: undefined,
        partnerFrom: undefined,
        partnerTo: undefined,
        status: undefined,
        requestDate: undefined,
        feeType: undefined,
        itemName: undefined,
        remarks: undefined,
        cost: undefined,
        currency: 'JPY',
        covers: undefined,
        occurs: undefined,
        paymentPeriod: undefined,
      },
      rules: {
        partnerFrom: [
          {
            required: true,
            type: 'number',
            message: this.$t('Partner From is required'),
            trigger: 'change',
          },
        ],
        partnerTo: [
          {
            required: true,
            type: 'number',
            message: this.$t('Partner To is required'),
            trigger: 'change',
          },
        ],
        feeType: [
          {
            required: true,
            message: this.$t('Fee Type is required'),
            trigger: 'change',
          },
        ],
        itemName: [
          {
            required: true,
            message: this.$t('Item Name is required'),
            trigger: 'change',
          },
        ],
        cost: [
          {
            required: true,
            type: 'number',
            message: this.$t('Cost Per Month is required'),
            trigger: 'change',
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t('Currency is required'),
            trigger: ['change', 'blur'],
          },
        ],
        covers: [
          {
            required: true,
            message: this.$t('Covers is required'),
            trigger: 'change',
          },
        ],
        occurs: [
          {
            required: true,
            message: this.$t('Occurs is required'),
            trigger: 'change',
          },
        ],
        requestDate: [
          {
            required: true,
            type: 'date',
            message: this.$t('Request Date is required'),
            trigger: 'change',
          },
        ],
        paymentPeriod: [
          {
            required: true,
            type: 'date',
            message: this.$t('Payment Period is required'),
            trigger: 'change',
          },
        ],
      },
      size: 'large',
    };
  },
  computed: {
    filteredPartnerList() {
      return this.partnerList.filter((p) => p.id !== this.form.partnerFrom);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.$emit('submit', { ...this.form });
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      if (this.value) {
        this.$emit('cancel');
      }
    },
  },
};
</script>
