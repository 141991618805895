<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New Payment') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <PaymentForm
          ref="form"
          :is-submitting="isSubmitting"
          :partner-list="partnerList"
          @submit="handleSubmit"
        />
        <a-alert v-if="payment" type="success">
          <template #message>
            {{ $t('added') }}
            <router-link to="/payments">
              {{ $t('View payments page') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add New Payment": "Add New Payment",
"added": "payment added,",
"view": "View payments page"
},
"ja": {
"Add New Payment": "新規支払いを追加",
"added": "支払いが追加されました。",
"view": "支払いを閲覧"
}
}
</i18n>

<script>
import PaymentForm from '@/views/payments/components/PaymentForm';

export default {
  name: 'Add',
  components: { PaymentForm },
  data() {
    return {
      payment: undefined,
      isSubmitting: false,
      error: '',
      partnerList: [],
    };
  },
  created() {
    this.getPartnerList();
  },
  methods: {
    getPartnerList() {
      this.$store.dispatch('partners/list', { infinite: true }).then((res) => {
        this.partnerList = res.list;
      });
    },
    handleSubmit(data) {
      this.isSubmitting = true;
      this.payment = undefined;
      this.error = '';
      this.$store
        .dispatch('payments/add', data)
        .then(() => {
          this.isSubmitting = false;
          this.payment = data;
          this.$refs.form.resetFields();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.error = err.response.data.errors.message;
          }
          this.isSubmitting = false;
        });
    },
  },
};
</script>
