<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Payments') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm />
          </div>
          <div v-permission="['add-payment']" class="column is-narrow">
            <router-link class="ant-btn ant-btn-lg ant-btn-primary" to="/payments/add">
              {{ $t('Add New Payment') }}
            </router-link>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="payments"
          :loading="isFetching"
          :custom-row="rowClicked"
          row-key="id"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #partnerFrom="{ record }">
            {{ record.fromPartner && record.fromPartner.name }}
          </template>
          <template #partnerTo="{ record }">
            {{ record.toPartner && record.toPartner.name }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #requestDate="{ record }">
            {{ $filters.date(record.requestDate, { format: $variables.dateFormat }) }}
          </template>
          <template #feeType="{ record }">
            {{ $t(record.feeType) }}
          </template>
          <template #itemName="{ record }">
            {{ record.itemName }}
          </template>
          <template #remarks="{ record }">
            {{ record.remarks }}
          </template>
          <template #action="{ record }">
            <Delete
              action-handler="payments/delete"
              :payload-value="{ id: record.id }"
              :title="$t('title')"
              :description="$t('desc')"
              :success-title="$t('deleted-title')"
              :success-description="$t('deleted-desc')"
              @close="fetchData"
            />
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Payments": "Payments",
"Add New Payment": "Add New Payment",
"From Partner": "From Partner",
"To Partner": "To Partner",
"Status": "Status",
"Request date": "Request date",
"Fee Type": "Fee Type",
"Item Name": "Item Name",
"Remarks": "Remarks",
"Delete": "Delete",
"Cancel": "Cancel",
"title": "Delete Payment?",
"desc": "Are you sure you want to delete this payment?",
"deleted-title": "Payment is deleted",
"deleted-desc": "Payment has been removed."
},
"ja": {
"Payments": "支払い",
"Add New Payment": "新規支払いを追加",
"From Partner": "支払元",
"To Partner": "支払先",
"Status": "ステータス",
"Request date": "リクエスト日",
"Fee Type": "費用種類",
"Item Name": "アイテム名",
"Remarks": "備考",
"Delete": "削除",
"Cancel": "取り消し",
"title": "支払いを削除?",
"desc": "支払いを本当に削除しますか？",
"deleted-title": "支払いは削除",
"deleted-desc": "支払いは削除"
}
}
</i18n>

<script>
import checkPermission from '@/utils/permission';
import SearchForm from '@/views/payments/components/SearchForm';
import Delete from '@/components/PopupDelete';

export default {
  name: 'Payments',
  components: {
    SearchForm,
    Delete,
  },
  data() {
    return {
      payments: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('From Partner'),
          dataIndex: 'partnerFrom',
          slots: { customRender: 'partnerFrom' },
        },
        {
          title: this.$t('To Partner'),
          dataIndex: 'partnerTo',
          slots: { customRender: 'partnerTo' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Request date'),
          dataIndex: 'requestDate',
          key: 'requestDate',
          slots: { customRender: 'requestDate' },
        },
        {
          title: this.$t('Fee Type'),
          dataIndex: 'feeType',
          key: 'feeType',
          slots: { customRender: 'feeType' },
        },
        {
          title: this.$t('Item Name'),
          key: 'itemName',
          dataIndex: 'itemName',
          slots: { customRender: 'itemName' },
        },
        {
          title: this.$t('Remarks'),
          key: 'remarks',
          dataIndex: 'remarks',
          slots: { customRender: 'remarks' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    canEditPayment() {
      return checkPermission(['modify-payment']);
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'payments') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('payments/list', this.$route.query)
        .then((resp) => {
          this.payments = resp.list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updatePaymentStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store.dispatch('payments/update', { id: record.id, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'payments-edit', params: { id: record.id } });
        },
      };
    },
    contactNumber(record) {
      this.$router.push({ name: 'payment-room', params: { id: record.id } });
    },
  },
};
</script>
